import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useTraining() {
  // Misc
  const { t } = useI18n();
  const store = useStore();

  // Computed
  const clientName = computed(() => store.getters["client/name"]);

  const {
    ALL_OPTIONS,
    defaultSystemOptions,
    durationTypeOptions,
    getCertificates,
    getDepartments
  } = useOptions();

  // CONSTANTS
  const view_code = "Trainings";
  const route = "teaching-trainings";
  const endpoint = "teaching.trainings";
  const searchFields = ["name"];
  const types = {
    name: inputTypes.TEXT,
    details: inputTypes.TEXT,
    status: inputTypes.SELECT,
    version: inputTypes.TEXT,
    code: inputTypes.TEXT,
    author: inputTypes.TEXT,
    available_registrations: inputTypes.NUMBER,
    duration: inputTypes.NUMBER,
    duration_type: inputTypes.SELECT,
    available_from: inputTypes.DATE,
    available_until: inputTypes.DATE,
    credits: inputTypes.NUMBER,
    requires_registration_approval: inputTypes.SELECT,
    requires_completion_approval: inputTypes.SELECT,
    price: inputTypes.NUMBER,
    completion_deadline_days: inputTypes.NUMBER,
    completion_deadline_days_new_learners: inputTypes.NUMBER,
    certificate_template: inputTypes.SELECT,
    available_to: inputTypes.SELECT,
    sys_admin_locked: inputTypes.SELECT,
    tags: inputTypes.TAGS,
    mandatory_for: inputTypes.SELECT,
    department_id: inputTypes.SELECT,
    date_activated: inputTypes.DATE,
    date_archived: inputTypes.DATE,
    assign_competencies_upon: inputTypes.SELECT
  };
  const localizedFields = ["name", "details"];
  const relationFields = [{ image_file_id: "image" }];
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.name"),
      value: "name"
    },
    {
      text: t("app.version"),
      value: "version"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    version: "1.0",
    code: "",
    author: clientName.value,
    available_registrations: null,
    duration: 60,
    duration_type: ALL_OPTIONS.MINUTES.value,
    available_from: "",
    available_until: "",
    credits: "",
    requires_registration_approval: ALL_OPTIONS.NO.value,
    assign_competencies_upon: ALL_OPTIONS.LEARNER_COMPLETION.value,
    requires_completion_approval: ALL_OPTIONS.NO.value,
    price: 0,
    completion_deadline_days: 30,
    completion_deadline_days_new_learners: 60,
    certificate_template: "",
    available_to: ALL_OPTIONS.INTERNAL_LEARNERS.value,
    sys_admin_locked: ALL_OPTIONS.NO.value,
    name: "",
    details: "",
    status: ALL_OPTIONS.UNDER_REVIEW.value,
    image_file_id: "",
    tags: "",
    mandatory_for: ALL_OPTIONS.ALL_LEARNERS.value,
    department_id: "",
    date_activated: "",
    date_archived: ""
  });
  const rules = ref({
    version: { required },
    code: {},
    author: {},
    available_registrations: {},
    duration: {},
    duration_type: {},
    available_from: {},
    available_until: {},
    credits: {},
    requires_registration_approval: { required },
    requires_completion_approval: { required },
    price: {},
    completion_deadline_days: {},
    assign_competencies_upon: { required },
    completion_deadline_days_new_learners: {},
    certificate_template: {},
    available_to: { required },
    sys_admin_locked: { required },
    name: { required },
    details: {},
    status: { required },
    image_file_id: {},
    tags: {},
    mandatory_for: { required },
    department_id: {},
    date_activated: {},
    date_archived: {}
  });
  const options = ref({
    certificate_template: [],
    duration_type: durationTypeOptions,
    requires_registration_approval: defaultSystemOptions,
    requires_completion_approval: defaultSystemOptions,
    available_to: [
      ALL_OPTIONS.INTERNAL_LEARNERS,
      ALL_OPTIONS.EXTERNAL_LEARNERS,
      ALL_OPTIONS.INTERNAL_EXTERNAL_LEARNERS
    ],
    sys_admin_locked: defaultSystemOptions,
    status: [
      ALL_OPTIONS.ACTIVE,
      ALL_OPTIONS.ARCHIVED,
      ALL_OPTIONS.UNDER_REVIEW
    ],
    mandatory_for: [ALL_OPTIONS.ALL_LEARNERS, ALL_OPTIONS.NEW_LEARNERS],
    department_id: [],
    assign_competencies_upon: [
      ALL_OPTIONS.LEARNER_COMPLETION,
      ALL_OPTIONS.COMPLETION_APPROVAL
    ]
  });

  // METHODS
  const getOptions = async () => {
    options.value.certificate_template = await getCertificates();
    options.value.department_id = await getDepartments();

    return options.value;
  };

  return {
    relationFields,
    headers,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    getOptions,
    value,
    rules,
    options,
    localizedFields
  };
}
