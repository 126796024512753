import i18n from "@/i18n";
import * as validators from "@vuelidate/validators";
import { createI18nMessage } from "@vuelidate/validators";

const { t } = i18n.global;
const i18nMessage = createI18nMessage({ t });

export const required = i18nMessage(validators.required);
export const email = i18nMessage(validators.email);
export const sameAs = i18nMessage(validators.sameAs, {
  withArguments: true
});
export const minLength = i18nMessage(validators.minLength, {
  withArguments: true
});
export const maxLength = i18nMessage(validators.maxLength, {
  withArguments: true
});
